<template>
  <div class="mt-4 card shadow w-100 p-4 text-center">
    <h1>Coming Soon...</h1>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Home",
  components: {
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  },
  mounted() {},
};
</script>
